<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom card-sticky">
              <div class="card-header" style="">
                <div class="card-title">
                  <h3 class="card-label">Lorem ipsum</h3>
                </div>
                <CardToolbar
                  @submitEvent="onSubmit"
                  back-url="/notifications/list"
                  buttonText="Add Notification"
                ></CardToolbar>
              </div>
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <div class="row justify-content-center">
                      <div class="col-xl-9">
                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >Title: <span class="text-danger">*</span></label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                id="name"
                                class="form-control form-control-lg"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="notification.title"
                                name="name"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >Description:
                          </label>
                          <div class="col-lg-9 col-xl-9">
                            <textarea
                              class="form-control"
                              v-model="notification.description"
                              rows="4"
                              placeholder=""
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >Is Active:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <CSwitch
                              @checkedEvent="toggleIsActive"
                              classes="switch-icon"
                              name="isActive"
                              :is-checked="notification.isActive"
                            />
                          </div>
                        </div>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >Expired Date:
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <vc-date-picker
                                v-model="notification.expiredDate"
                                :masks="masks"
                              >
                                <template
                                  v-slot="{ inputValue, togglePopover }"
                                >
                                  <div
                                    class="input-group"
                                    @click="togglePopover()"
                                  >
                                    <input
                                      id="date"
                                      :class="{ 'is-invalid': errors[0] }"
                                      class="form-control"
                                      :value="inputValue"
                                      name="date"
                                      type="text"
                                    />
                                    <div
                                      class="input-group-prepend cursor-pointer"
                                    >
                                      <span class="input-group-text"
                                        ><i class="la la-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </template>
                              </vc-date-picker>
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >Notification Start Date:
                              <span class="text-danger">*</span></label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <vc-date-picker
                                v-model="notification.notificationStartDate"
                                :masks="masks"
                              >
                                <template
                                  v-slot="{ inputValue, togglePopover }"
                                >
                                  <div
                                    class="input-group"
                                    @click="togglePopover()"
                                  >
                                    <input
                                      id="date"
                                      :class="{ 'is-invalid': errors[0] }"
                                      class="form-control"
                                      :value="inputValue"
                                      name="date"
                                      type="text"
                                    />
                                    <div
                                      class="input-group-prepend cursor-pointer"
                                    >
                                      <span class="input-group-text"
                                        ><i class="la la-calendar"></i
                                      ></span>
                                    </div>
                                  </div>
                                </template>
                              </vc-date-picker>
                            </div>
                          </div>
                        </validation-provider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >Frequency Type:</label
                          >
                          <div class="col-lg-9 col-xl-9"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import ApiService from "@/core/services/api.service";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import CSwitch from "@/view/content/components/switch/CSwitch.vue";

extend("required", {
  ...required,
  message: "",
});

export default {
  name: "EditNotification",
  components: { CSwitch, CardToolbar, ValidationProvider, ValidationObserver },
  data() {
    return {
      notification: {
        title: null,
        description: null,
        isActive: true,
        expiredDate: null,
        notificationStartDate: null,
        frequencyType: "daily",
      },
      isNotificationLoaded: false,
      masks: {
        input: "DD.MM:YYYY.",
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    ApiService.get("notifications/", this.$route.params.id).then(({ data }) => {
      this.notification.title = data.title;
      this.notification.description = data.description;
      this.notification.isActive = data.isActive;
      this.notification.expiredDate = data.expiredDate;
      this.notification.notificationStartDate = data.notificationStartDate;
      this.notification.frequencyType = data.frequencyType;

      this.isNotificationLoaded = true;
    });
  },
  methods: {
    toggleIsActive() {
      this.notification.isActive = !this.notification.isActive;
    },
    onSubmit(type) {
      this.$refs.observerRef.validate().then((isValid) => {
        if (isValid) {
          ApiService.post("notifications", this.notification)
            .then(() => {
              this.$notify({
                group: "notification",
                type: "success",
                title: "Notification successfully added to the database",
              });
              if (type === "1") {
                setTimeout(() => {
                  this.$router.push({ name: "notifications-list" });
                }, 1000);
              }
            })
            .catch((error) => {
              this.$notify({
                group: "notification",
                type: "error",
                title: error,
              });
            });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Please enter all mandatory fields",
          });
        }
      });
    },
  },
};
</script>
